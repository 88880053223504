// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-products-abh-prism-eye-shadow-palette-jsx": () => import("./../src/products/abh-prism-eye-shadow-palette.jsx" /* webpackChunkName: "component---src-products-abh-prism-eye-shadow-palette-jsx" */),
  "component---src-products-anastasia-beverly-hills-liquid-glow-highlighter-oyster-jsx": () => import("./../src/products/anastasia-beverly-hills-liquid-glow-highlighter-oyster.jsx" /* webpackChunkName: "component---src-products-anastasia-beverly-hills-liquid-glow-highlighter-oyster-jsx" */),
  "component---src-products-anastasia-beverly-hills-liquid-lip-gloss-caramel-jsx": () => import("./../src/products/anastasia-beverly-hills-liquid-lip-gloss-caramel.jsx" /* webpackChunkName: "component---src-products-anastasia-beverly-hills-liquid-lip-gloss-caramel-jsx" */),
  "component---src-products-baby-got-peach-shadow-palette-jsx": () => import("./../src/products/baby-got-peach-shadow-palette.jsx" /* webpackChunkName: "component---src-products-baby-got-peach-shadow-palette-jsx" */),
  "component---src-products-big-tough-girl-pressed-powder-highlighter-jsx": () => import("./../src/products/big-tough-girl-pressed-powder-highlighter.jsx" /* webpackChunkName: "component---src-products-big-tough-girl-pressed-powder-highlighter-jsx" */),
  "component---src-products-bird-of-paradise-shadow-palette-jsx": () => import("./../src/products/bird-of-paradise-shadow-palette.jsx" /* webpackChunkName: "component---src-products-bird-of-paradise-shadow-palette-jsx" */),
  "component---src-products-blue-moon-shadow-palette-jsx": () => import("./../src/products/blue-moon-shadow-palette.jsx" /* webpackChunkName: "component---src-products-blue-moon-shadow-palette-jsx" */),
  "component---src-products-california-love-shadow-palette-jsx": () => import("./../src/products/california-love-shadow-palette.jsx" /* webpackChunkName: "component---src-products-california-love-shadow-palette-jsx" */),
  "component---src-products-coronation-pressed-powder-highlighter-jsx": () => import("./../src/products/coronation-pressed-powder-highlighter.jsx" /* webpackChunkName: "component---src-products-coronation-pressed-powder-highlighter-jsx" */),
  "component---src-products-dream-street-shadow-palette-jsx": () => import("./../src/products/dream-street-shadow-palette.jsx" /* webpackChunkName: "component---src-products-dream-street-shadow-palette-jsx" */),
  "component---src-products-floating-lights-pressed-powder-blush-jsx": () => import("./../src/products/floating-lights-pressed-powder-blush.jsx" /* webpackChunkName: "component---src-products-floating-lights-pressed-powder-blush-jsx" */),
  "component---src-products-going-coconuts-shadow-palette-jsx": () => import("./../src/products/going-coconuts-shadow-palette.jsx" /* webpackChunkName: "component---src-products-going-coconuts-shadow-palette-jsx" */),
  "component---src-products-good-as-gold-shadow-palette-jsx": () => import("./../src/products/good-as-gold-shadow-palette.jsx" /* webpackChunkName: "component---src-products-good-as-gold-shadow-palette-jsx" */),
  "component---src-products-juvias-place-the-saharan-palette-jsx": () => import("./../src/products/juvia's-place-the-saharan-palette.jsx" /* webpackChunkName: "component---src-products-juvias-place-the-saharan-palette-jsx" */),
  "component---src-products-juvias-place-nubian-2-eye-shadow-palette-jsx": () => import("./../src/products/juvia’s-place-nubian-2-eye-shadow-palette.jsx" /* webpackChunkName: "component---src-products-juvias-place-nubian-2-eye-shadow-palette-jsx" */),
  "component---src-products-lilac-you-a-lot-shadow-palette-jsx": () => import("./../src/products/lilac-you-a-lot-shadow-palette.jsx" /* webpackChunkName: "component---src-products-lilac-you-a-lot-shadow-palette-jsx" */),
  "component---src-products-love-bird-shadow-palette-jsx": () => import("./../src/products/love-bird-shadow-palette.jsx" /* webpackChunkName: "component---src-products-love-bird-shadow-palette-jsx" */),
  "component---src-products-lunar-beauty-lifes-a-drag-eyeshadow-palette-jsx": () => import("./../src/products/lunar-beauty-life's-a-drag-eyeshadow-palette.jsx" /* webpackChunkName: "component---src-products-lunar-beauty-lifes-a-drag-eyeshadow-palette-jsx" */),
  "component---src-products-mint-to-be-shadow-palette-jsx": () => import("./../src/products/mint-to-be-shadow-palette.jsx" /* webpackChunkName: "component---src-products-mint-to-be-shadow-palette-jsx" */),
  "component---src-products-night-owl-shadow-palette-jsx": () => import("./../src/products/night-owl-shadow-palette.jsx" /* webpackChunkName: "component---src-products-night-owl-shadow-palette-jsx" */),
  "component---src-products-ofra-the-lip-affair-lip-set-jsx": () => import("./../src/products/ofra-the-lip-affair-lip-set.jsx" /* webpackChunkName: "component---src-products-ofra-the-lip-affair-lip-set-jsx" */),
  "component---src-products-orange-you-glad-shadow-palette-jsx": () => import("./../src/products/orange-you-glad-shadow-palette.jsx" /* webpackChunkName: "component---src-products-orange-you-glad-shadow-palette-jsx" */),
  "component---src-products-shadow-palette-main-squeeze-jsx": () => import("./../src/products/shadow-palette--main-squeeze.jsx" /* webpackChunkName: "component---src-products-shadow-palette-main-squeeze-jsx" */),
  "component---src-products-sol-shadow-palette-jsx": () => import("./../src/products/sol-shadow-palette.jsx" /* webpackChunkName: "component---src-products-sol-shadow-palette-jsx" */),
  "component---src-products-take-me-back-to-brazil-jsx": () => import("./../src/products/take-me-back-to-brazil.jsx" /* webpackChunkName: "component---src-products-take-me-back-to-brazil-jsx" */),
  "component---src-products-uhhuh-honey-shadow-palette-jsx": () => import("./../src/products/uhhuh-honey-shadow-palette.jsx" /* webpackChunkName: "component---src-products-uhhuh-honey-shadow-palette-jsx" */),
  "component---src-messages-404-jsx": () => import("./../src/messages/404.jsx" /* webpackChunkName: "component---src-messages-404-jsx" */),
  "component---src-messages-cancel-jsx": () => import("./../src/messages/cancel.jsx" /* webpackChunkName: "component---src-messages-cancel-jsx" */),
  "component---src-messages-success-jsx": () => import("./../src/messages/success.jsx" /* webpackChunkName: "component---src-messages-success-jsx" */),
  "component---src-messages-success-form-jsx": () => import("./../src/messages/successForm.jsx" /* webpackChunkName: "component---src-messages-success-form-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-new-jsx": () => import("./../src/pages/new.jsx" /* webpackChunkName: "component---src-pages-new-jsx" */),
  "component---src-pages-sale-jsx": () => import("./../src/pages/sale.jsx" /* webpackChunkName: "component---src-pages-sale-jsx" */)
}

